import Avatar01 from "../images/fpo/avatar-01.svg";
import Avatar02 from "../images/fpo/avatar-02.svg";
import Avatar03 from "../images/fpo/avatar-03.svg";
import Avatar04 from "../images/fpo/avatar-04.svg";

[
  {
    avatar: Avatar01,
    name: "name-1",
    wins: [1],
  },
  {
    avatar: Avatar02,
    name: "name-2",
  },
  {
    avatar: Avatar03,
    name: "name-3",
  },
  {
    avatar: Avatar04,
    name: "name-4",
    wins: [1],
  },
];

export const BRACKETS_DATA = {
  default: {
    entrants: [
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
  },

  active: {
    entrants: [
      {
        avatar: Avatar01,
        name: "name-1",
      },
      {
        avatar: Avatar02,
        name: "name-2",
      },
      {
        avatar: Avatar03,
        name: "name-3",
      },
      {
        avatar: Avatar04,
        name: "name-4",        
      },
      {
        avatar: Avatar01,
        name: "name-5",
      },
      {
        avatar: Avatar03,
        name: "name-6",        
      },
      {
        avatar: Avatar04,
        name: "name-7",
      },
      {
        name: "name-8",
      },
      {
        avatar: Avatar01,
        name: "name-9",
      },
      {
        avatar: Avatar02,
        name: "name-10",
      },
      {
        avatar: Avatar03,
        name: "name-11",
      },
      {
        avatar: Avatar04,
        name: "name-12",
      },
      {
        avatar: Avatar01,
        name: "name-13",
      },
      {
        avatar: Avatar03,
        name: "name-14",
      },
      {
        name: "name-15",
      },
      {
        name: "name-16",
      },
      {
        avatar: Avatar01,
        name: "name-1",
      },
      {
        avatar: Avatar02,
        name: "name-2",
      },
      {
        avatar: Avatar03,
        name: "name-3",
      },
      {
        avatar: Avatar04,
        name: "name-4",
      },
      {
        avatar: Avatar01,
        name: "name-5",
      },
      {
        avatar: Avatar03,
        name: "name-6",
      },
      {
        name: "name-7",
      },
      {
        name: "name-8",
      },
      {
        avatar: Avatar01,
        name: "name-9",
      },
      {
        avatar: Avatar02,
        name: "name-10",
      },
      {
        avatar: Avatar03,
        name: "name-11",
      },
      {
        avatar: Avatar04,
        name: "name-12",
      },
      {
        avatar: Avatar01,
        name: "name-13",
      },
      {
        avatar: Avatar03,
        name: "name-14",
      },
      {
        name: "name-15",
      },
      {
        name: "name-16",
      },
    ],
  },

  completed: {
    entrants: [
      {
        avatar: Avatar01,
        name: "Team 1",
        wins: [1],
      },
      {
        avatar: Avatar02,
        name: "Team 2",
      },
      {
        avatar: Avatar03,
        name: "Team 3",
      },
      {
        avatar: Avatar04,
        name: "Team 4",
        wins: [1],
      },
      {
        avatar: Avatar01,
        name: "Team 5",
        wins: [1],
      },
      {
        avatar: Avatar02,
        name: "Team 6",
      },
      {
        avatar: Avatar03,
        name: "Team 7",
      },
      {
        avatar: Avatar04,
        name: "Team 8",
      },
    ],
  },
};
