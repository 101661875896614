import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useQueryParam, StringParam } from "use-query-params";
import axios from 'axios';

import { PageLayout } from "../layouts";
import { GetStartedBlock, AboutPartieBlock, TournamentEvents, Block, TrackedLink } from "../components";

import TournamentHeroImg from "../images/Clash/clash.svg";

import "../global/styles/page-styles/tournament-page.scss";

import { BRACKETS_DATA } from "../mockData/bracket-data";

const API_ENDPOINT = process.env.API_ENDPOINT;
const API_ENDPOINT_AVATAR_LIST = process.env.API_ENDPOINT_AVATAR_LIST;

const Tournament = () => {
  const [tournamentState, setTournamentState] = useQueryParam(
    "tournamentState",
    StringParam
  );
  const [tournamentData, setTournamentData] = useState(undefined);

  const pageSize = 12;
  const gameId = "53c79124-0f78-42ed-8304-077c0afd686c";

  const trackClickEvent = (data) => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", data)
    }
  }

  /**
   *
   * Get Tournment Data when the component mounts.
   * Data is passed down to the Tournament Events component.
   *
   */
  useEffect(() => {
    /**
     * 
     * Pass in Search Queries as query params.
     * 
     */
    axios.get(`${API_ENDPOINT}?pageSize=${pageSize}&gameId=${gameId}`)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          axios.get(`${API_ENDPOINT_AVATAR_LIST}?roomId=${res.data[i].id}`)
            .then((res2) => {
              res.data[i].people = res2.data
              setTournamentData({
                title: "Call Of Duty®: Warzone™ Community Clash",
                tournamentState: "Active",
                currentEvent: BRACKETS_DATA[tournamentState || "completed"],
                upcomingEvents: res.data
              });
            })
            .catch(error => {
              console.log('Fetch error :: ', error);
            })
        }
      })
      .catch(error => {
        console.log('Fetch error :: ', error);
      })
  }, []);

  return (
    <>
      <Helmet>
        <title>Partie | Tournament</title>
        <html lang="en" />
        <meta name="description" content="Partie makes gaming more accessible, inclusive and rewarding. Connect and play with others through all-in-one Partie Matchmaking, Voice, Video and Text Chat." />
      </Helmet>
      <PageLayout className="tournament-page" hero>
        <Block hero lazy>
          <div className="partie-block__column">
            <h2 className="partie-block__title">
              Call Of Duty®: Warzone™ wants you to join the Partie
            </h2>
            <p className="partie-block__description">
              Compete alongside your favorite creator in Call Of Duty®: Warzone™ for a shot at a $30,000 prize pool!
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn" to="/clash" children="Join now"/>
            </div>
          </div>
          <div className="partie-block__column">
            <img src={TournamentHeroImg} alt="Clash Event" />
          </div>
        </Block>
        <AboutPartieBlock />
        <TournamentEvents data={tournamentData} length={pageSize} />
        <GetStartedBlock />
      </PageLayout>
    </>
  );
};

export default Tournament;